import antdSA from 'antd/es/locale/si_LK'; // Import the English (en_US) locale
import saMessages from "../locales/es_ES.json";

const saLang = {
  messages: {
    ...saMessages
  },
  antd: antdSA,
  locale: 'es'
};
export default saLang;
