import React, { useEffect, useState } from "react";
import { Form, Menu, Popover } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import CustomScrollbars from "../../util/CustomScrollbars";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { hideAuthLoader, showAuthLoader } from "../../appRedux/actions";
import Service from "../../service";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./SidebarContent.css";
import PropTypes from "prop-types";
import { getRoles } from "../../util/hasPermission";
import ProjectListModal from "../../components/Modal/ProjectListModal";
import { generateCacheKey } from "../../util/generateCacheKey";

function SidebarContent({ setSidebarCollapsed }) {
  const { themeType } = useSelector(({ settings }) => settings);
  const { pathname } = useSelector(({ common }) => common);

  const location = useLocation();
  const dispatch = useDispatch();

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const [openKeys, setOpenKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isComplaintPopoverVisible, setIsComplaintPopoverVisible] = useState(false);
  const [recentList, setRecentList] = useState([]);
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (window.location.pathname == "/organization-view") {
      setSidebarCollapsed(true);
      setOpenKeys([]);
    } else {
      setSidebarCollapsed(false);
    }
  }, [window.location.pathname]);

  // open modal and calling getlisting api
  const showModal = async () => {
    setIsModalOpen(true);
    getProjectListing();
    getVisitedData();
  };

  const getProjectListing = async (searchText) => {
    try {
      dispatch(showAuthLoader());
      const defaultPayload = {
        pageNo: 1,
        limit: 5,
        search: searchText || "",
        // search: '',
        sortBy: "desc",
        filterBy: "all",
        isSearch: true,
      };
      const reqBody = {
        ...defaultPayload,
      };
      if (searchText && searchText !== "") {
        reqBody.search = searchText;
        // setSearchEnabled(true);
      }
      let Key = generateCacheKey("project", reqBody);

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getProjectdetails,
        body: reqBody,
        options: {
          cachekey: Key,
        },
      });
      dispatch(hideAuthLoader());
      if (response?.data && response?.data?.data) {
        setProjectDetails(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addVisitedData = async (projectId) => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.addrecentVisited,
        body: {
          project_id: projectId,
        },
      });
      if (response?.data && response?.data?.statusCode == 200) {
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log("add project error");
    }
  };

  const getVisitedData = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getrecentVisited,
      });
      if (response?.data && response?.data?.statusCode == 200) {
        dispatch(hideAuthLoader());
        setRecentList(response?.data?.data);
      }
    } catch (error) {
      console.log("get project error");
    }
  };

  const handleNavLinkClick = (e) => {
    e.preventDefault(); // Prevent default navigation behavior
    setIsPopoverVisible(true); // Show the Popover
  };

  const handleComplaintNavLinkClick = (e) => {
    e.preventDefault(); // Prevent default navigation behavior
    setIsComplaintPopoverVisible(true); // Show the Popover
  };

  return (
    <>
      {/* <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      /> */}
      <div className="gx-sidebar-content sidebar-menu">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            openKeys={openKeys}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item
              key="Search Project"
              className={
                location.pathname?.includes("/search-project-list")
                  ? "ant-menu-item-current"
                  : ""
              }
            >
              <NavLink
                to="#"
                onClick={() => {
                  showModal();
                }}
              >
                {/* <i class="fi fi-rr-dashboard"></i> */}
                {/* <i class="fa-solid fa-magnifying-glass"></i> */}
                <SearchOutlined style={{ marginLeft: "20px" }} />
                <span>
                  {/* <IntlMessages id="sidebar.search-project-list" /> */}
                  Search
                </span>
              </NavLink>
            </Menu.Item>
            {!getRoles(["Client"]) && (
              <Menu.Item
                key="Dashboard"
                className={
                  location.pathname?.includes("/dashboard")
                    ? "ant-menu-item-current"
                    : ""
                }
              >
                <NavLink to="/dashboard">
                  <i className="fi fi-rs-house-chimney"></i>
                  <span>
                    <IntlMessages id="sidebar.dashboard" />
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            <Menu.Item
              key="Admin Dashboard"
              className={
                location.pathname?.includes("/project-list") ||
                  location.pathname?.includes("/project/app")
                  ? "ant-menu-item-current"
                  : ""
              }
            >
              <NavLink to="/project-list">
                <i className="fi fi-rr-dashboard"></i>
                <span>
                  <IntlMessages id="sidebar.project-list" />
                </span>
              </NavLink>
            </Menu.Item>

            {getRoles(["Super Admin"]) && (
              <Menu.Item
                key="Users"
                className={
                  location.pathname?.includes("/project-users")
                    ? "ant-menu-item-current"
                    : ""
                }
              >
                <NavLink to="/project-users">
                  <i className="fi fi-rr-users-alt"></i>
                  <span>
                    <IntlMessages id="sidebar.project-users" />
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {getRoles(["Super Admin"]) && (
              <Menu.Item
                key="Permission"
                className={
                  location.pathname?.includes("/permission-access") ||
                    location.pathname?.includes("/roles-permission")
                    ? "ant-menu-item-current"
                    : ""
                }
              >
                <NavLink to="/permission-access">
                  <i className="fi fi-rr-lock"></i>
                  <span>
                    <IntlMessages id="sidebar.permission" />
                  </span>
                </NavLink>
              </Menu.Item>
            )}

            {/* {!getRoles(["Admin","User"]) && (
              <Menu.Item
                key="Library"
                className={
                  location.pathname?.includes("/my-library")
                    ? "ant-menu-item-active"
                    : ""
                }
              >
                <NavLink to="/my-library">
                  <i className="fi fi-rr-book"></i>
                  <span>
                    <IntlMessages id="sidebar.my-library" />
                  </span>
                </NavLink>
              </Menu.Item>
            )} */}
            {getRoles(["Super Admin", "Admin"]) && (
              <Menu.Item
                key="Reports"
                className={
                  location.pathname?.includes("/timesheet-reports") ||
                    location.pathname?.includes("/project-runnig-reports")
                    ? "ant-menu-item-current"
                    : ""
                }
              >
                <Popover
                  placement="left"
                  visible={isPopoverVisible}
                  onVisibleChange={setIsPopoverVisible}
                  className={
                    location.pathname?.includes("/reports") ||
                      location.pathname?.includes("/timesheet-reports") ||
                      location.pathname?.includes("/project-runnig-reports")
                      ? "ant-menu-item-current"
                      : ""
                  }
                  content={
                    <div className="right-popover-wrapper">
                      <Link
                        to="/project-runnig-reports"
                        onClick={() => setIsPopoverVisible(false)}
                      >
                        <p>Projects- running</p>
                      </Link>
                      <Link
                        to="/timesheet-reports"
                        onClick={() => setIsPopoverVisible(false)}
                      >
                        <p>Timesheet</p>
                      </Link>
                    </div>
                  }
                >
                  <NavLink to="/reports" onClick={handleNavLinkClick}>
                    <i className="fi fi-rs-newspaper"></i>
                    <span>
                      <IntlMessages id="sidebar.reports" />
                    </span>
                  </NavLink>
                </Popover>
              </Menu.Item>
            )}


            {getRoles(["Super Admin", "PC", "TL", "Admin", "AM", "User"]) && (
              <Menu.Item
                key="Hours"
                className={
                  location.pathname?.includes("/billable-hours") ||
                    location.pathname?.includes("/billable-hours-pc")
                    ? "ant-menu-item-current"
                    : ""
                }
              >
                {getRoles(["Super Admin", "PC", "TL", "Admin", "AM", "User"]) && (
                  <NavLink to="/billable-hours">
                    <i class="fa-solid fa-clock"></i>
                    <span>
                      <IntlMessages id="sidebar.hours" />
                    </span>
                  </NavLink>
                )}
                {/* { getRoles(["PC"]) &&(
                   <NavLink to="/billable-hours-pc">
                   <i class="fa-solid fa-clock"></i>
                     <span>
                       <IntlMessages id="sidebar.hours" />
                     </span>
                   </NavLink>
               )} */}

              </Menu.Item>
            )}
            {getRoles(["Super Admin", "PC", "TL", "Admin","AM"]) && (
              <Menu.Item
                key="FeedBack"
                className={
                  location.pathname?.includes("/complaints") ||
                    location.pathname?.includes("/positive-review")
                    ? "ant-menu-item-current"
                    : ""
                }
              >
                <Popover
                  placement="left"
                  visible={isComplaintPopoverVisible}
                  onVisibleChange={setIsComplaintPopoverVisible}
                  className={
                    location.pathname?.includes("/feedback") ||
                      location.pathname?.includes("/positive-review") ||
                      location.pathname?.includes("/complaints")
                      ? "ant-menu-item-current"
                      : ""
                  }
                  content={
                    <div className="right-popover-wrapper">
                      <Link
                        to="/positive-review"
                        onClick={() => setIsComplaintPopoverVisible(false)}
                      >
                        <p>Positive Reviews</p>
                      </Link>
                      <Link
                        to="/complaints"
                        onClick={() => setIsComplaintPopoverVisible(false)}
                      >
                        <p>Complaints</p>
                      </Link>
                    </div>
                  }
                >
                  <NavLink to="/feedback" onClick={handleComplaintNavLinkClick}>
                    <i class="fa-solid fa-comments"></i>
                    <span>
                      <IntlMessages id="sidebar.feedBack" />
                    </span>
                  </NavLink>
                </Popover>
              </Menu.Item>
            )}

          </Menu>

          <ProjectListModal
            projectDetails={projectDetails}
            recentList={recentList}
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            addVisitedData={addVisitedData}
            setIsModalOpen={setIsModalOpen}
            form={form}
            getProjectListing={getProjectListing}
          />
        </CustomScrollbars>
      </div>
    </>
  );
}

SidebarContent.propTypes = {
  setSidebarCollapsed: PropTypes.func.isRequired,
};
export default SidebarContent;
